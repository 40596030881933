import app from '../../../client/feathersClient'
import { useCallback } from 'react'
import ExcelJS from 'exceljs'
import { useNotify } from 'react-admin'
import * as FileSaver from 'file-saver'
import maxBy from 'lodash/maxBy'
import { format, getQuarter, getYear } from 'date-fns'

const headers = [
  { label: 'Contract Number', key: 'contractNumber' },
  { label: 'Contract Supplier (Admin Fee)', key: 'contractSupplier', formatter: () => 'Surplus Marketplace' },
  { label: 'Supplier Name', key: 'Supplier Name', formatter: () => 'Surplus Marketplace' },
  { label: 'HUB', key: 'HUB', formatter: () => 'N' },
  { label: 'Institution Agency Code', key: 'institutionCode' },
  { label: 'Institution Name', key: 'institutionName' },
  { label: 'Product Description', key: 'Product Description', formatter: () => 'Auction' },
  {
    label: 'Transaction Date (mm/dd/yyyy)',
    key: 'date',
    formatter: (v) => (v ? format(new Date(v), 'MM/dd/yyyy') : undefined),
  },
  { label: 'Total Spend', key: 'sales' },
  { label: 'Admin Fee %', key: 'fee', formatter: () => 1 },
  { label: 'Admin Fee $', key: 'commission' },
  { label: 'Admin Fee Generated', key: 'Admin Fee Generated', formatter: () => 'Y' },
  { label: 'Invoice Number', key: 'Invoice Number', formatter: () => 'N/A' },
]

const useUtAllianceReport = (query) => {
  const notify = useNotify()
  const fetchData = async () => {
    const res = await app.service('reports').find({ query: { ...query, type: 'utAlliance' } })
    return { data: res.data?.data || [], totals: res.data?.totals || [] }
  }

  return useCallback(async () => {
    try {
      const { data: initData } = await fetchData()
      const formatMap = headers?.reduce((acc, { key, formatter }, idx) => {
        acc[key] = { format: formatter, sort: idx }
        return acc
      }, {})
      const data = initData.map((el) => {
        headers.forEach(({ key }) => {
          if (key in el) return
          el[key] = ''
        })
        const entries = Object.entries(el)
          .map(([key, value]) => {
            const fn = (v) => v
            const formatFn = formatMap[key]?.format || fn

            return key in formatMap ? [key, formatFn(value)] : []
          })
          .filter((e) => e.length)
        entries.sort(([a], [b]) => {
          return formatMap[a].sort - formatMap[b].sort
        })
        return entries.map(([_, val]) => val)
      })
      notify('Generating UT Alliance reports...', 'info', {}, false, 2000)

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const workbook = new ExcelJS.Workbook()
      const sheet = workbook.addWorksheet('Reporting')

      // dates
      const dates = initData.reduce((acc, { date }) => {
        acc.push(new Date(date))
        return acc
      }, [])
      const year = getYear(maxBy(dates, (e) => getYear(e)))
      const quarter = getQuarter(maxBy(dates, (e) => getQuarter(e)))
      sheet.getRow(1).values = ['Reporting Year', year]
      sheet.getRow(2).values = ['Reporting Quarter', `Q${quarter}`]

      sheet.addTable({
        name: 'Reporting',
        ref: 'A4',
        headerRow: true,
        totalsRow: false,
        columns: headers.map(({ label, ...rest }) => {
          return {
            ...rest,
            name: label,
          }
        }),
        rows: data,
      })

      const filename = `utAlliance-${year}-Q${quarter}`

      const buffer = await workbook.xlsx.writeBuffer(filename)
      const blob = new Blob([buffer], { type: fileType })
      FileSaver.saveAs(blob, filename + fileExtension)
    } catch (e) {
      console.log('error', e)
      notify('Error occurred during generating report.', 'error', {}, false, 3000)
    }
  }, [fetchData, notify])
}

export default useUtAllianceReport
