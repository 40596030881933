import { feathers } from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import hooks from './hooks'
import { authentication } from '@feathersjs/client'

const makeApp = () => {
  const app = feathers()

  const restClient = rest(process.env.REACT_APP_API_URL)

  app.configure(restClient.fetch(window.fetch.bind(window)))

  app.configure(
    authentication({
      jwtStrategy: 'jwt',
      storage: window.localStorage,
      header: 'authorization',
    }),
  )

  app.hooks(hooks)
  return app
}

const app = makeApp()

export default app
