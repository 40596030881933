import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FormTab,
  SelectInput,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  TabbedForm,
  TextField,
  TextInput,
  Pagination,
  BooleanInput,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import { Typography, Box } from '@material-ui/core'
import HeaderTextField from '../../elements/HeaderTextField'
import PriceField from '../../elements/PriceField'
import useRepostDrawer from '../items/repost/useRepostDrawer'
import DrawerButton from '../../elements/DrawerButton'
import CloudinaryFileInput from '../../elements/CloudinaryFileInput'
import Toolbar from '../../elements/Toolbar'
import LinkToClient from './LinkToClient'

export const SellerEdit = (props) => {
  const { renderDrawer, toggleModal } = useRepostDrawer(props)

  return (
    <>
      <Edit {...props} title={<HeaderTextField source="username" label="Seller:" />}>
        <TabbedForm toolbar={<Toolbar />}>
          <FormTab label="summary">
            <LinkToClient>
              <Box m={2}>
                <Typography>Internal Auction</Typography>
              </Box>
            </LinkToClient>

            <TextInput source="username" label="Username" validate={required()} />
            <TextInput source="password" label="Password" type="password" />
            <NumberInput source="commission" label="Commission [%]" validate={required()} />
            <NumberInput source="buyerPremium" label="Buyer's Premium [%]" />
            <ReferenceInput source="salesTaxId" reference="stripe/taxes" label="Sales Tax" allowEmpty>
              <SelectInput optionText={'percentage'} />
            </ReferenceInput>
            <Typography variant="h6">Removal Information</Typography>
            <TextInput source="removal.email" label="Email" validate={required()} />
            <TextInput source="removal.companyName" label="Company Name" validate={required()} />
            <TextInput source="removal.firstName" label="First Name" validate={required()} />
            <TextInput source="removal.lastName" label="Last Name" validate={required()} />
            <TextInput source="removal.address1" label="Address 1" />
            <TextInput source="removal.address2" label="Address 2" />
            <ReferenceInput
              source="removal.locationId"
              reference="locations"
              label="Location"
              sort={{
                field: 'city',
                order: 'ASC',
              }}
              filterToQuery={(searchText) => {
                return (
                  searchText && {
                    'city[$regex]': searchText,
                    'city[$options]': 'i',
                  }
                )
              }}
              allowEmpty
            >
              <AutocompleteInput
                optionText={(record) => {
                  if (!record?._id) {
                    return 'clear search'
                  }
                  return `${record.city}, ${record.state}`
                }}
              />
            </ReferenceInput>
            <TextInput source="removal.zip" label="Zip" />
            <TextInput source="removal.phone" label="Phone" />
            <RichTextInput source="removal.description" label="Description" multiline fullWidth />
            <Typography variant="h6">Inspection Information</Typography>
            <RichTextInput source="inspection.description" label="Description" multiline fullWidth />
            <Typography variant="h6">Other Information</Typography>
            <RichTextInput source="termsAndConditions" label="Terms And Conditions" multiline fullWidth />
            <RichTextInput source="additionalInfo" label="Additional Info" multiline fullWidth />
          </FormTab>
          <FormTab label={'Redistribution'} path="redistribution">
            <SelectInput
              source="themeName"
              label="Redistribution theme"
              choices={[
                { id: 'mdAnderson', name: 'MD Anderson' },
                { id: 'uta', name: 'UT Arlington' },
                { id: 'utsw', name: 'UT Southwestern' },
                { id: 'uth', name: 'UTHealth' },
                { id: 'utscsa', name: 'UTHSC-SA' },
                { id: 'utsa', name: 'UT San Antonio' },
              ]}
              allowEmpty
            />
            <Typography variant="h6">Redistribution Information</Typography>
            <TextInput source="redistribution.email" label="Redistribution Email" />
            <RichTextInput source="redistribution.terms" label="Redistribution Terms" multiline fullWidth />
          </FormTab>
          <FormTab label="items" path="items">
            <ReferenceManyField
              addLabel={false}
              fullWidth
              reference="items"
              target="sellerId"
              filter={{ sellerId: props.id }}
              pagination={<Pagination />}
            >
              <Datagrid>
                <TextField source="name" label="Title" />
                <ReferenceField source="categoryId" reference="categories" label="Category">
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="shortId" />
                <TextField source="lotId" />
                <PriceField source="initialPrice" />
                <TextField source="status" label="Status" />
                <DateField source="endDate" showTime />
                <DrawerButton
                  handleClick={toggleModal}
                  reference="_id"
                  service="items"
                  disable={props.record?.status !== 'unsold'}
                >
                  Repost item
                </DrawerButton>
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="invoices" path="invoices">
            <ReferenceManyField
              addLabel={false}
              fullWidth
              reference="invoices"
              target="sellerId"
              filter={{ sellerId: props.id }}
              pagination={<Pagination />}
            >
              <Datagrid>
                <TextField source={'shortId'} label={'Number'} />
                <ReferenceField source="bidderId" reference="bidders" label="Bidder">
                  <TextField source="username" />
                </ReferenceField>
                <TextField source="status" />
                <PriceField source="summary.bidderTotal" label="Total price" labelOnValue={false} />
                <DateField source="paymentDate" label="Pay at" showTime />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Accounting">
            <TextInput source="email" label="Email" type="email" validate={required()} />
            <TextInput source="accounting.companyName" label="Company Name" />
            <TextInput source="accounting.firstName" label="First Name" />
            <TextInput source="accounting.lastName" label="Last Name" />
            <TextInput source="accounting.address1" label="Address 1" />
            <TextInput source="accounting.address2" label="Address 2" />
            <TextInput source="accounting.zip" label="Zip" />
            <TextInput source="accounting.phone" label="Phone" />
            <TextInput source="accounting.taxNumber" label="Tax Number" />
            <ReferenceInput
              source="accounting.locationId"
              reference="locations"
              label="Location"
              sort={{
                field: 'city',
                order: 'ASC',
              }}
              filterToQuery={(searchText) => {
                return (
                  searchText && {
                    'city[$regex]': searchText,
                    'city[$options]': 'i',
                  }
                )
              }}
              allowEmpty
            >
              <AutocompleteInput
                optionText={(record) => {
                  if (!record?._id) {
                    return 'clear search'
                  }
                  return `${record.city}, ${record.state}`
                }}
              />
            </ReferenceInput>
            <TextInput source="accounting.sellerInfo" multiline fullWidth label={'Seller Info'} />
            <CloudinaryFileInput source="accounting.fileUrl" editable />
          </FormTab>

          <FormTab label="UT Alliance">
            <BooleanInput source={'inUtAlliance'} label={'in UT Alliance'} />
            <TextInput source="utAlliance.contractNumber" label={'Contract Number'} />
            <TextInput source="utAlliance.institutionName" label={'Institution Name'} />
            <TextInput source="utAlliance.institutionCode" label={'Institution Code'} />
          </FormTab>
        </TabbedForm>
      </Edit>
      {renderDrawer()}
    </>
  )
}
