import { useNotify } from 'react-admin'
import { useCallback } from 'react'
import * as FileSaver from 'file-saver'
import startCase from 'lodash/startCase'
import logo from '../../logoBase64.json'

import ExcelJS from 'exceljs'

export const formatter = {
  date: (v) => (v ? new Date(v) : undefined),
}

const summaryHeaders = [
  { key: 'seller' },
  { key: 'sales' },
  { key: 'tax' },
  { key: 'taxable' },
  { key: 'buyerPremium' },
  { key: 'commission' },
  { key: 'bidderTotal' },
  { key: 'sellerTotal' },
]

const financialHeaders = [
  { key: 'itemShortId' },
  { key: 'invoiceNumber' },
  { key: 'lotId' },
  { key: 'title' },
  { key: 'endDate', formatter: formatter.date },
  { key: 'sales' },
  { key: 'commission' },
  { key: 'buyerPremium' },
  { key: 'tax' },
  { key: 'taxable' },
  { key: 'bidderTotal' },
  { key: 'total' },
  { key: 'bidder' },
  { key: 'bidderEmail' },
  { key: 'bidderPhone' },
  { key: 'bidderAddress1' },
  { key: 'bidderCity' },
  { key: 'bidderState' },
  { key: 'bidderPostalCode' },
  { key: 'bidderCountry' },
  { key: 'assetNumbers' },
  { key: 'bidderAddress2' },
]

const preliminaryHeaders = [
  { key: 'invoiceNr', filterButton: true },
  { key: 'lotId' },
  { key: 'title' },
  { key: 'endDate', formatter: formatter.date },
  { key: 'invoiceStatus' },
  { key: 'bidder' },
  { key: 'bidderEmail' },
  { key: 'bidderPhone' },
  { key: 'itemStatus' },
  { key: 'itemAssetNumber' },
  { key: 'price', totalKey: 'total' },
]

const headers = {
  preliminary: preliminaryHeaders,
  financial: financialHeaders,
  summary: summaryHeaders,
}

const useToFile = ({ data, totals, type, filename }) => {
  const notify = useNotify()

  return useCallback(async () => {
    try {
      const formatMap = headers[type]?.reduce((acc, { key, formatter }, idx) => {
        acc[key] = { format: formatter, sort: idx }
        return acc
      }, {})
      data = data.map((el) => {
        const entries = Object.entries(el)
          .map(([key, value]) => {
            const fn = (v) => v
            const formatFn = formatMap[key]?.format || fn

            return key in formatMap ? [key, formatFn(value)] : []
          })
          .filter((e) => e.length)
        entries.sort(([a], [b]) => {
          return formatMap[a].sort - formatMap[b].sort
        })
        return entries.map(([_, val]) => val)
      })
      notify('Generating reports...', 'info', {}, false, 2000)

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const workbook = new ExcelJS.Workbook()
      const sheet = workbook.addWorksheet('data')
      const image = workbook.addImage({
        base64: logo.logo,
        extension: 'png',
      })
      sheet.addImage(image, 'A1:D7')

      sheet.addTable({
        name: startCase(type),
        ref: 'A9',
        headerRow: true,
        totalsRow: true,
        style: {
          showRowStripes: true,
        },
        columns: headers[type].map(({ key, totalKey, ...rest }) => {
          return {
            ...rest,
            name: startCase(key),
            totalsRowFunction: totalKey in totals || key in totals ? 'custom' : 'none',
            totalsRowFormula: totals[totalKey || key],
          }
        }),
        rows: data,
      })

      const buffer = await workbook.xlsx.writeBuffer(filename)
      const blob = new Blob([buffer], { type: fileType })
      FileSaver.saveAs(blob, filename + fileExtension)
    } catch (e) {
      console.log(e)
      notify('Error occurred during generating report.', 'error', {}, false, 3000)
    }
  }, [data, totals, type, filename])
}

export default useToFile
