import React, { useState, useMemo } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { grey } from '@material-ui/core/colors'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import useToFile from '../useToFile'
import useReports from '../useReports'
import { format } from 'date-fns'
import useUtAllianceReport from './useUtAllianceReport'

const SummaryReports = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const query = useMemo(() => {
    return {
      type: 'summary',
      startDate,
      endDate,
    }
  }, [startDate, endDate])
  const { data, totals } = useReports(query)

  const generateReport = useToFile({
    data,
    type: 'summary',
    totals,
    filename: `summaryReport(${format(startDate, 'MM.dd.yyyy')}-${format(endDate, 'MM.dd.yyyy')})`,
  })

  const generateUtAllianceReport = useUtAllianceReport(query)

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1} direction={'column'}>
        <Grid
          item
          xs={12}
          align="center"
          style={{
            marginTop: 20,
          }}
        >
          <Typography variant="h5">Summary report</Typography>
        </Grid>
        <Grid container item spacing={2} xs={12} direction={'row'}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container item xs={12} sm={6} md={3} direction="column">
              <Typography variant={'caption'} style={{ marginRight: '16px' }}>
                Start date
              </Typography>
              <DatePicker value={startDate} onChange={setStartDate} />
            </Grid>
            <Grid container item xs={12} sm={6} md={3} direction="column">
              <Typography variant={'caption'} style={{ marginRight: '16px' }}>
                End date
              </Typography>
              <DatePicker value={endDate} onChange={setEndDate} />
            </Grid>
            <Grid container item xs={12} sm={6} md={3} alignContent="center">
              <Button variant={'contained'} color={'primary'} onClick={generateReport}>
                Generate report XLSX
              </Button>
            </Grid>
            <Grid container item xs={12} sm={6} md={3} alignContent="center">
              <Button variant={'outlined'} color={'primary'} onClick={generateUtAllianceReport}>
                Generate UT Alliance report
              </Button>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Seller</TableCell>
                  <TableCell>Sales</TableCell>
                  <Tooltip title="(Sales + BP) * tax" placement="down">
                    <TableCell>Tax</TableCell>
                  </Tooltip>
                  <Tooltip title="Sales + BP" placement="down">
                    <TableCell>Total Taxable</TableCell>
                  </Tooltip>
                  <TableCell>Buyer Premium</TableCell>
                  <TableCell>Commission</TableCell>
                  <Tooltip title="-1% if in UT aliance" placement="down">
                    <TableCell>Nett Commission</TableCell>
                  </Tooltip>
                  <Tooltip title="Sales + BP + tax" placement="down">
                    <TableCell>Bidder total</TableCell>
                  </Tooltip>
                  <Tooltip title="Sales - Commission" placement="down">
                    <TableCell>Total Payable</TableCell>
                  </Tooltip>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>{row.seller}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" color="primary">
                        {row.sales}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.tax}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.taxable}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.buyerPremium}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.commission}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.netCommission}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.bidderTotal}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" color="primary">
                        {row.sellerTotal}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow style={{ background: grey[200] }}>
                  <TableCell>Total:</TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" color="primary">
                      {totals['sales']}
                    </Typography>
                  </TableCell>
                  <TableCell>{totals['tax']}</TableCell>
                  <TableCell>{totals['taxable']}</TableCell>
                  <TableCell>{totals['buyerPremium']}</TableCell>
                  <TableCell>{totals['commission']}</TableCell>
                  <TableCell>{totals['netCommission']}</TableCell>
                  <TableCell>{totals['bidderTotal']}</TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" color="primary">
                      {totals['sellerTotal']}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SummaryReports
