import React from 'react'
import { Admin, Resource } from 'react-admin'
import { restClient } from 'ra-data-feathers'
import feathersClient from './client/feathersClient'
import { AdminCreate, AdminEdit, AdminList } from './models/admins'
import { FinancialReporterCreate, FinancialReporterEdit, FinancialReporterList } from './models/financialReporter'
import i18nProvider from './i18nProvider'

import Person from '@material-ui/icons/Person'
import Store from '@material-ui/icons/Store'
import ShoppingBasket from '@material-ui/icons/ShoppingBasket'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ListIcon from '@material-ui/icons/List'
import MoneyIcon from '@material-ui/icons/MonetizationOn'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import SettingsIcon from '@material-ui/icons/Settings'
import PaymentIcon from '@material-ui/icons/Payment'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import EmailIcon from '@material-ui/icons/Email'
import CategoryIcon from '@material-ui/icons/Category'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import RoomIcon from '@material-ui/icons/Room'
import AssessmentIcon from '@material-ui/icons/Assessment'
import { ItemList } from './models/items/List'
import { InvoiceEdit } from './models/invoices'
import { InvoiceList } from './models/invoices/List'
import { WatchListList } from './models/watchLists'
import { SessionList } from './models/sessions'
import { StripeSetupIntentsList } from './models/stripeSetupIntents'
import { StripePaymentMethodsList } from './models/stripePaymentMethods'
import EmailsList from './models/emails/List'
import EmailsCreate from './models/emails/Create'
import EmailsEdit from './models/emails/Edit'
import { StripeCustomersEdit, StripeCustomersList } from './models/stripeCustomers'
import { MaxBidList } from './models/maxBids/List'
import { MaxBidCreate } from './models/maxBids/Create'
import { MaxBidEdit } from './models/maxBids/Edit'
import { BidList } from './models/bids/List'
import { BidShow } from './models/bids/Show'
import { CategoryCreate, CategoryEdit, CategoryList } from './models/categories'
import { ItemCreate } from './models/items/Create'
import { ItemEdit } from './models/items/Edit'
import { FaqCreate, FaqEdit, FaqList } from './models/faqs'
import { SettingCreate, SettingEdit, SettingList } from './models/settings'
import Dashboard from './layout/Dashboard'
import { StripeTaxesEdit, StripeTaxesList } from './models/stripeTaxes'
import { LocationCreate, LocationEdit, LocationList } from './models/locations'
import { AuctionsCreate } from './models/auctions/Create'
import { AuctionList } from './models/auctions/List'
import { AuctionsEdit } from './models/auctions/Edit'
import { SellerList } from './models/sellers/List'
import { SellerCreate } from './models/sellers/Create'
import { SellerEdit } from './models/sellers/Edit'
import PreliminaryReports from './models/reports/preliminaryReport'
import FinancialReports from './models/reports/financialReport'
import SummaryReports from './models/reports/summaryReport'
import CustomLayout from './layout'
import BidderEdit from './models/bidders/Edit'
import BidderCreate from './models/bidders/Create'
import BidderList from './models/bidders/List'
import authProvider from './client/auth'

const restClientOptions = {
  shortId: 'id',
  id: '_id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true, // Use PATCH instead of PUT for updates
}

const App = () => {
  return (
    <Admin
      dataProvider={restClient(feathersClient, restClientOptions)}
      authProvider={authProvider(feathersClient)}
      locale="en"
      i18nProvider={i18nProvider}
      layout={CustomLayout}
      dashboard={Dashboard}
    >
      <Resource name="users" />
      <Resource name="generate/invoiceEmails" />
      <Resource name="stripe/payment-intents" />
      <Resource
        options={{ label: 'Preliminary reports' }}
        name="preliminaryReports"
        list={PreliminaryReports}
        icon={AssessmentIcon}
      />
      <Resource
        options={{ label: 'Financial reports', permissions: ['reports'] }}
        name="financialReports"
        list={FinancialReports}
        icon={AssessmentIcon}
      />
      <Resource
        options={{ label: 'Summary reports', permissions: ['reports'] }}
        name="summaryReports"
        list={SummaryReports}
        icon={AssessmentIcon}
      />
      <Resource name="admins" icon={Person} list={AdminList} create={AdminCreate} edit={AdminEdit} />
      <Resource
        name="financial-reporters"
        icon={Person}
        list={FinancialReporterList}
        create={FinancialReporterCreate}
        edit={FinancialReporterEdit}
      />
      <Resource name="bidders" icon={ShoppingBasket} list={BidderList} create={BidderCreate} edit={BidderEdit} />
      <Resource name="sellers" icon={Store} list={SellerList} create={SellerCreate} edit={SellerEdit} />
      <Resource
        name="auctions"
        list={AuctionList}
        create={AuctionsCreate}
        edit={AuctionsEdit}
        label={'Post Auctions'}
      />
      <Resource
        name="invoices"
        options={{ label: 'Invoices' }}
        icon={MoneyIcon}
        list={InvoiceList}
        edit={InvoiceEdit}
      />
      <Resource name="items" icon={ListIcon} list={ItemList} create={ItemCreate} edit={ItemEdit} />
      <Resource name="categories" icon={CategoryIcon} list={CategoryList} create={CategoryCreate} edit={CategoryEdit} />
      <Resource name="emails" icon={EmailIcon} list={EmailsList} create={EmailsCreate} edit={EmailsEdit} />
      <Resource name="faqs" icon={QuestionAnswerIcon} list={FaqList} create={FaqCreate} edit={FaqEdit} />
      <Resource name="settings" icon={SettingsIcon} list={SettingList} create={SettingCreate} edit={SettingEdit} />
      <Resource name="locations" icon={RoomIcon} list={LocationList} create={LocationCreate} edit={LocationEdit} />
      <Resource name="sessions" icon={AccessTimeIcon} list={SessionList} />
      <Resource name="watchLists" icon={BookmarkBorderIcon} list={WatchListList} />
      <Resource
        name="maxBids"
        options={{ label: 'Max Bids' }}
        list={MaxBidList}
        create={MaxBidCreate}
        edit={MaxBidEdit}
      />
      <Resource name="bids" options={{ label: 'Bids' }} list={BidList} show={BidShow} />
      <Resource
        name="stripe/customers"
        options={{ label: 'Stripe Customers' }}
        icon={PeopleOutlineIcon}
        edit={StripeCustomersEdit}
        list={StripeCustomersList}
      />
      <Resource
        name="stripe/taxes"
        options={{ label: 'Stripe Taxes' }}
        icon={AccountBalanceIcon}
        list={StripeTaxesList}
        edit={StripeTaxesEdit}
      />
      <Resource
        name="stripe/payment-methods"
        options={{ label: 'Stripe Payment Methods' }}
        icon={PaymentIcon}
        list={StripePaymentMethodsList}
      />
      <Resource
        name="stripe/setup-intents"
        options={{ label: 'Stripe Setup Intents' }}
        icon={SettingsIcon}
        list={StripeSetupIntentsList}
      />
    </Admin>
  )
}

export default App
